<template>
  <div class="testimony-wrapper" id="testimony">
    <v-container>
      <div
        class="header-wrapper"
        :class="{
          'header-wrapper-mobile': isMobile,
          'header-wrapper-desktop': !isMobile,
        }"
      >
        <div
          class="concept-title"
          :class="{ 'concept-title-mobile first-title-mobile': isMobile }"
          data-aos="fade-right"
          data-aos-delay="50"
        >
          {{ $t("home.testimony-header-1") }} &nbsp;
        </div>
        <div
          class="concept-title-brand mt-7 ml-16"
          :class="{ 'concept-title-mobile second-title-mobile': isMobile }"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          {{ $t("home.testimony-header-2") }}
        </div>
      </div>
      <div data-aos="fade-up" data-aos-delay="800">
        <vueper-slides
          class="no-shadow"
          :class="{
            'testimony-slides-desktop': !isMobile,
            'testimony-slides-mobile': isMobile,
          }"
          :visible-slides="isMobile ? 1 : 2"
          slide-multiple
          :slide-ratio="1 / 4"
          :gap="0"
          :fixed-height="!isMobile ? '500px' : '550px'"
        >
          <vueper-slide
            v-for="(i, index) in slides"
            :key="index"
            :title="lang === 'kr' ? i.KRtitle : i.title"
            :content="getContent(i)"
            :image="i.image"
          />
        </vueper-slides>
      </div>
    </v-container>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { desktopContent, mobileContent } from "../utils/testimonyContent";

export default {
  name: "TestimonySlides",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      rating: 5,
      slides: [],
    };
  },
  created() {
    this.slides = !this.isMobile ? desktopContent : mobileContent;
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
    lang () {
     return this.$router.currentRoute.params.lang;
    },
  },
  methods: {

    getContent (i) {
      if (this.lang === 'kr') {
        return i.KRcontent
      } else if (this.lang === 'id') {
        return i.IDcontent
      } else {
        return i.content
      }
    }
  }
};
</script>

<style scoped>
.concept-title {
  font-family: "Josefin Slab", serif;
  text-shadow: -0.7px -0.7px 0 #3a5a40, 0.7px -0.7px 0 #3a5a40,
    -0.7px 0.7px 0 #3a5a40, 0.7px 0.7px 0 #3a5a40;
  color: #dad7cd;
  font-size: 100px;
}

.concept-title-brand {
  font-family: "'Josefin Slab'", sans-serif;
  color: #3a5a40;
  font-size: 100px;
}

.first-title-mobile {
  line-height: 2rem;
  font-size: 55px !important;
}

.second-title-mobile {
  font-size: 50px !important;
}

.concept-title-mobile {
  margin: 0 !important;
}
.header-wrapper {
  line-height: 70px;
}
.header-wrapper-desktop {
  padding-top: 7rem;
}
.header-wrapper-mobile {
  margin-top: 4rem;
}

.testimony-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #dad7cd;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.testimony-slides-desktop {
  padding-top: 4.5rem;
}

.testimony-slides-mobile {
  margin-top: -2rem;
}
</style>
