<template>
  <div class="home">
    <Banner />
    <!-- <AboutUs /> -->
    <CategoryList />
    <Testimony />
    <TranslateButton />
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import Testimony from "@/components/TestimonySlides";
// import AboutUs from "@/components/AboutUs";
import CategoryList from "@/components/CategoryList.vue";
import TranslateButton from "@/components/TranslateButton";

export default {
  name: "HomeView",
  components: {
    Banner,
    Testimony,
    // AboutUs,
    CategoryList,
    TranslateButton,
  },
  data() {
    return {}
  },
  // created() {
  //   this.$route.params.lang
  // },
  methods: {
    async fetchData() {},
  },
};
</script>
