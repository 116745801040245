<template>
  <div class="product-by-category-wrapper">
    <div
      class="category-header-wrapper"
      :class="{ 'category-header-wrapper-mobile': isMobile }"
    >
      <v-container :class="{ 'mt-6': isMobile }">
        <div
          class="header-wrapper"
          :class="{ 'header-wrapper-mobile': isMobile }"
        >
          <div
            class="concept-title first-title"
            :class="{ 'concept-title-mobile': isMobile }"
            data-aos="fade-right"
            data-aos-delay="50"
          >
            {{ $t("allProduct.header-title-1") }}
          </div>
          <div
            class="second-title-brand"
            :class="{ 'second-title-mobile': isMobile }"
            data-aos="fade-left"
            data-aos-delay="60"
          >
            {{ $t("allProduct.header-title-2") }}
          </div>
        </div>
      </v-container>
    </div>
    <div class="product-list-wrapper mt-5 pb-15">
      <ProductList
        :isMobile="isMobile"
        :isMedScreen="isMedScreen"
        :productData="productData"
      />
    </div>
    <hr />
    <ItemRecommend />
  </div>
</template>

<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/firebase";
import ProductList from "@/components/ProductList.vue";
import ItemRecommend from "@/components/ItemRecommend.vue";

export default {
  name: "AllProduct",
  components: {
    ProductList,
    ItemRecommend,
  },
  data() {
    return {
      productData: [],
    };
  },
  created() {
    document.documentElement.style.overflow = 'hidden'
    this.$isLoading(true);
    this.fetchData();
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    isMedScreen() {
      return window.innerWidth < 1600;
    },
    isIpad() {
      return window.innerWidth < 1025;
    },
    selectedCategory() {
      return this.$route.params.category;
    },
  },
  methods: {
    async fetchData() {
      const querySnapshot = await getDocs(collection(db, "product"));
      let data = [];
      let riceData = [];
      let cocoa = []
      querySnapshot.forEach((doc) => {
        // console.log(doc.data());
        const productData = doc.data();
        if (productData.category === "rice") {
          riceData.push(productData);
        } else if (productData.ENname.includes('cocoa')) {
          cocoa.push(productData);
        } else {
          data.push(productData);
        }
      });
      this.productData = [...data, ...cocoa, ...riceData];
      setTimeout(() => {
        document.documentElement.style.overflow = 'auto'
        this.$isLoading(false);
      }, 2700);
    },
  },
};
</script>

<style scoped>
.product-by-category-wrapper {
  height: 100%;
  color: black;
}

.category-header-wrapper {
  height: 35vh;
  background-color: #3a5a40;
  align-items: center;
  display: flex;
}

.category-header-wrapper-mobile {
  height: 33vh;
  align-items: center;
  text-align: center;
}

.concept-title {
  font-family: "Josefin Slab", serif;
  /* text-shadow: -0.7px -0.7px 0 #dad7cd, 0.7px -0.7px 0 #dad7cd,
    -0.7px 0.7px 0 #dad7cd, 0.7px 0.7px 0 #dad7cd;
  color: #3a5a40; */

  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #dad7cd;

  font-size: 100px;
  text-transform: uppercase;
}

.concept-title-mobile {
  padding-top: 0.8rem;
  font-size: 60px !important;
  margin: 0 !important;
  line-height: 5rem;
}

.second-title-mobile {
  text-align: center;
  font-size: 60px !important;
  line-height: 2rem;
  margin: 0 !important;
}

.first-title {
  line-height: 5rem;
  margin-bottom: 1rem;
}

.header-wrapper-mobile {
  display: center;
  justify-content: center;
}

.second-title-brand {
  font-family: "'Josefin Slab'", sans-serif;
  color: #dad7cd;
  font-size: 100px;
  text-transform: uppercase;
}

.header-wrapper {
  line-height: 4rem;
}

hr {
  border: none;
  border-top: 0.5rem dashed #3a5a40;
  color: #3a5a40;
  background-color: #fff;
  height: 1px;
  width: 100%;
  letter-spacing: 1rem;
}

.product-list-wrapper {
  background-color: #dad7cd;
}
</style>