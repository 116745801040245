<template>
  <vueper-slides
    ref="myVueperSlides"
    :parallax="parallax"
    :parallax-fixed-content="parallaxFixedContent"
    :arrows="false"
    fixed-height="100vh"
    fade
    autoplay
    :bullets="false"
  >
    <vueper-slide
      v-for="(slide, i) in slides"
      :key="i"
      :image="slide.image"
      :content="slide.content"
    />
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
const bannerBaseUrl =
  "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/";

export default {
  name: "HomeBanner",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      parallax: -1,
      parallaxFixedContent: true,
      slides: [
        {
          image: `${bannerBaseUrl}banner-1.jpg?alt=media&token=c62266b6-67ed-493b-a489-0e5d01666213`,
        },
        {
          image: `${bannerBaseUrl}banner-2.jpg?alt=media&token=45ca17a3-009b-4469-b3d7-b58128ba9c91`,
        },
        {
          image: `${bannerBaseUrl}banner-3.jpg?alt=media&token=917acfa6-a0bc-4ae8-828b-09237b154509`,
        },
      ],
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  created() {
    const lang = this.$router.currentRoute.params.lang;
    let mainFontSize = "5rem";
    let subFontSize = "2.5rem";
    if (this.isMobile) {
      mainFontSize = "2.3rem";
      subFontSize = "1.4rem";
    }
    if (lang === "kr") {
      if (!this.isMobile) mainFontSize = "6rem";
      this.slides.map((e) => {
        e.content = `
          <div class="container">
            <div style="font-size: ${mainFontSize};color: #dad7cd">
              <b>
                매일의 식당은 건강한 몸과
                ${!this.isMobile ? `<br>` : ""}
                마음을 키우는 중요한 
                ${!this.isMobile ? `<br>` : ""}
                에너지입니다
              </b>
            </div>
            <div class="pt-3" style="font-size: ${subFontSize};color: #dad7cd">
              <b>
                우리의 제품은 살충제 및 첨가제 
                ${!this.isMobile ? `<br>` : ""}
                같은 성분을 사용하지 않습니다
              </b>
            </div>
          </div>
          `;
      });
    } else if (lang === "id") {
      this.slides.map((e) => {
        e.content = `
          <div class="container">
            <div style="font-size: ${mainFontSize};color: #dad7cd">
              <b>
                Makanan sehari-hari adalah 
                ${!this.isMobile ? `<br>` : ""}
                energi penting untuk menjaga
                ${!this.isMobile ? `<br>` : ""}
                kesehatan tubuh dan pikiran.
              </b>
            </div>
            <div class="pt-3" style="font-size: ${subFontSize};color: #dad7cd">
              <b>
                Produk kami tidak menggunakan bahan-bahan 
                ${!this.isMobile ? `<br>` : ""}
                seperti pestisida dan aditif
              </b>
            </div>
          </div>
          `;
      });
    } else {
      this.slides.map((e) => {
        e.content = `
          <div class="container">
            <div style="font-size: ${mainFontSize};color: #dad7cd">
              <b>
                Daily dining is an 
                ${!this.isMobile ? `<br>` : ""}
                important energy for nurturing 
                ${!this.isMobile ? `<br>` : ""}
                a healthy body and mind.
              </b>
            </div>
            <div class="pt-3" style="font-size: ${subFontSize};color: #dad7cd">
              <b>
                Our products do not use ingredients 
                ${!this.isMobile ? `<br>` : ""}
                such as pesticides and additives
              </b>
            </div>
          </div>
            `;
      });
    }
  },
};
</script>

<style scoped>
.parallax-img {
  height: 100vh;
}

.slide-title {
  font-size: 13rem;
  color: #dad7cd;
}
</style>
