var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"bar",attrs:{"color":"white","fixed":_vm.isMobile,"elevate-on-scroll":"","height":!_vm.isMobile ? '82px' : '62px'}},[_c('v-spacer'),_c('a',{class:{ 
      'mt-7': !_vm.isMobile, 
      'mt-2': _vm.isMobile,
    },attrs:{"href":"#testimony","text":""},on:{"click":_vm.toTestimony}},[_c('div',{staticClass:"font-weight-black green-text",class:{ 'small-font': _vm.isMobile }},[_vm._v(" "+_vm._s(_vm.$t("navbar.testimony"))+" ")])]),_c('a',{class:{ 'mx-10': _vm.isMobile, 'mx-custom': !_vm.isMobile },attrs:{"href":"#"}},[_c('v-toolbar-title',[_c('v-img',{attrs:{"height":"62","width":"105","src":_vm.logo},on:{"click":_vm.toHome}})],1)],1),_c('router-link',{class:{
      'mt-7': !_vm.isMobile, 
      'mt-2': _vm.isMobile, 
      'mr-6': _vm.isEN,
    },attrs:{"to":("/" + (_vm.$i18n.locale) + "/products")}},[_c('div',{staticClass:"font-weight-black green-text",class:{ 'small-font': _vm.isMobile }},[_vm._v(" "+_vm._s(_vm.$t("navbar.products"))+" ")])]),_c('v-spacer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }