<template>
  <v-app-bar
    color="white"
    :fixed="isMobile"
    elevate-on-scroll
    class="bar"
    :height="!isMobile ? '82px' : '62px'"
  >
    <v-spacer></v-spacer>
    <!-- <v-spacer v-if="!isEN && isMobile"></v-spacer> -->
    <a
      href="#testimony"
      text
      @click="toTestimony"
      :class="{ 
        'mt-7': !isMobile, 
        'mt-2': isMobile,
      }"
    >
      <div
        class="font-weight-black green-text"
        :class="{ 'small-font': isMobile }"
      >
        {{ $t("navbar.testimony") }}
      </div>
    </a>
    <a href="#" :class="{ 'mx-10': isMobile, 'mx-custom': !isMobile }">
      <v-toolbar-title>
        <v-img height="62" width="105" :src="logo" @click="toHome"></v-img>
      </v-toolbar-title>
    </a>

    <router-link
      :to="`/${$i18n.locale}/products`"
      :class="{
        'mt-7': !isMobile, 
        'mt-2': isMobile, 
        'mr-6': isEN,
      }"

    >
        <!-- 'mr-6': isEN && isMobile, -->
      <div
        :class="{ 'small-font': isMobile }"
        class="font-weight-black green-text"
      >
        {{ $t("navbar.products") }}
      </div></router-link
    >

    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import logo from "@/assets/logo.png";
export default {
  name: "NavBar",
  data() {
    return {
      logo,
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
    isEN() {
      const lang = this.$router.currentRoute.params.lang;
      return lang === "en" || lang === "id";
    },
  },
  methods: {
    toProducts() {
      this.$router.replace(`${this.$i18n.locale}/products`);
    },
    toHome() {
      this.$router.push("/");
    },
    toTestimony() {
      this.$router.push(`/${this.$i18n.locale}/#testimony`);
    },
  },
};
</script>

<style scoped>
.logo {
  cursor: pointer;
}

.bar {
  border-bottom: black solid 1px;
  /* font-family: "Gothic A1", sans-serif !important; */
  /* font-family: "Josefin Slab", serif !important; */
  text-transform: uppercase;
  z-index: 15
}
a {
  text-decoration: none;
}
.green-text {
  color: #3a5a40;
  font-weight: bold;
}
.content-wrapper {
  min-width: 100vw;
  display: flex;
  align-items: center;
}
.small-font {
  font-size: 0.75rem;
}

.mx-custom {
  margin: 0 5rem;
}
</style>
