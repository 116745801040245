<template>
  <div class="category-list-wrapper">
    <v-container>
      <div
        class="header-wrapper"
        :class="{ 'header-wrapper-mobile': isMobile }"
      >
        <div
          class="concept-title"
          :class="{ 'concept-title-mobile first-title-mobile': isMobile }"
          data-aos="fade-right" data-aos-delay="50"
        >
          {{ $t('home.product-category-header-1') }} &nbsp;
        </div>
        <div
          class="concept-title-brand mt-7 ml-16"
          :class="{ 'concept-title-mobile second-title-mobile': isMobile }"
          data-aos="fade-right" data-aos-delay="300"
        >
          {{ $t('home.product-category-header-2') }}
        </div>
      </div>
      <div
        class="category-wrapper"
        :class="{ 'category-wrapper-mobile': isMobile }"
        data-aos="fade-up" data-aos-delay="400"
      >
        <vueper-slides
          class="no-shadow white--text"
          :visible-slides="3"
          :gap="3"
          slide-multiple
          :slide-ratio="1 / 4"
          :dragging-distance="200"
          :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
          :fixed-height="!isMobile ? '300px' : '350px'"
          :bullets="true"
        >
          <vueper-slide
            v-for="(i, index) in slides"
            :key="index"
            :title="i.title"
            :content="i.content"
            :image="i.image"
            :link="`${categoryBaseUrl}${i.category}`"
          />

        </vueper-slides>
      </div>
    </v-container>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "CategoryList",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      lang: '',
      categoryBaseUrl: `https://royalnatural.net/${this.$router.currentRoute.params.lang}/category/`,
      slides: [
        {
          title: '<b style="font-size: 3rem;color: #dad7cd">Coffee</b>',
          content: '<b style="font-size: 3rem;color: #dad7cd">커피</b>',
          category: 'coffee-커피',
          image:
            "https://file003.shop-pro.jp/PA01474/231/images/category-coffee.jpg",
          redirectUrl:
            "https://file003.shop-pro.jp/PA01474/231/images/category-coffee.jpg",
        },
        {
          title: '<b style="font-size: 3rem;color: #dad7cd">Herbal</b>',
          category: 'herbal-허브',
          content: '<b style="font-size: 3rem;color: #dad7cd">허브</b>',
          image:
            "https://media.istockphoto.com/photos/honey-on-burlap-picture-id173687528?b=1&k=20&m=173687528&s=170667a&w=0&h=H78dgB3ye79WmcnduKszSc4iD89drCa1YoX8hxTM5SY=",
          redirectUrl:
            "https://media.istockphoto.com/photos/honey-on-burlap-picture-id173687528?b=1&k=20&m=173687528&s=170667a&w=0&h=H78dgB3ye79WmcnduKszSc4iD89drCa1YoX8hxTM5SY=",
        },
        {
          title: '<b style="font-size: 3rem;color: #dad7cd">Rice</b>',
          content: '<b style="font-size: 3rem;color: #dad7cd">쌀</b>',
          category: 'rice-쌀',
          image:
            "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/rice-category.png?alt=media&token=cef5afef-becd-441f-9b81-bb787f1a4c4a",
            redirectUrl: "https://file003.shop-pro.jp/PA01474/231/images/category-coffee.jpg",
        },
        {
          title: '<b style="font-size: 3rem;color: #dad7cd">Oil</b>',
          content: '<b style="font-size: 3rem;color: #dad7cd">오일</b>',
          category: 'oil-오일',
          image:
            "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/oil-category.jpg?alt=media&token=3337a18a-0350-4305-beb0-c9f92fafbd23",
          redirectUrl:
            "https://images.unsplash.com/photo-1474979266404-7eaacbcd87c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dmlyZ2luJTIwY29jb251dCUyMG9pbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
        },
        {
          title: '<b style="font-size: 2rem;color: #dad7cd">Cosmetic</b>',
          content: '<b style="font-size: 2rem;color: #dad7cd">화장품</b>',
          category: 'cosmetic-화장품',
          image:
            // "https://i.ibb.co/ZSHH7MN/photo-1556760544-74068565f05c-1-1.png",
            "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/cosmetic-category.jpg?alt=media&token=46fc5ffb-f2ab-4994-8649-534be5886d57",
          redirectUrl:
            "https://i.ibb.co/ZSHH7MN/photo-1556760544-74068565f05c-1-1.png",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  created () {
    this.lang = this.$router.currentRoute.params.lang; 
  },
  methods: {
    onSelectCategory(selectedCategory) {
      this.$router.push({
        name: `productByCategory/${this.$i18n.locale}`,
        params: {
          lang: this.$i18n.locale,
          name: selectedCategory,
        },
      });
    },

  },
};
</script>

<style scoped>
.category-list-wrapper {
  height: 100vh;
  background-color: #3a5a40;
}

.concept-title {
  font-family: "Josefin Slab", serif;
  text-shadow: -0.7px -0.7px 0 #dad7cd, 0.7px -0.7px 0 #dad7cd,
    -0.7px 0.7px 0 #dad7cd, 0.7px 0.7px 0 #dad7cd;
  color: #3a5a40;
  font-size: 100px;
}

.concept-title-brand {
  font-family: "'Josefin Slab'", sans-serif;
  color: #dad7cd;
  font-size: 100px;
}

.first-title-mobile {
  line-height: 2rem;
  font-size: 55px !important;
}

.second-title-mobile {
  font-size: 50px !important;
}

.concept-title-mobile {
  margin: 0 !important;
}
.header-wrapper {
  margin-top: 7rem;
  line-height: 70px;
}
.header-wrapper-mobile {
  margin-top: 4rem;
}
.vueperslides__progress {
  background: rgba(0, 0, 0, 0.25);
  color: #dad7cd;
}
.category-wrapper {
  margin-top: 10rem;
}

.category-wrapper-mobile {
  margin-top: 7rem;
}
.categories-content {
  color: #dad7cd;
  font-size: 1rem;
}

.category-title-text {
  margin-top: 4rem;

  text-align: center;
  font-size: 4em;
  color: #666;
  position: absolute;
  top: 100%;
  margin-top: 0.5em;
  left: 0;
  right: 0;
  z-index: 2;
}

a {
  text-decoration: none;
}

.category-title-text {
  color: rgb(72, 72, 72) !important;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.category-title-text:hover {
  color: grey !important;
}

.vueperslide__content {
  text-align: center;
  position: absolute;
  top: 0;
  margin-top: 0.5em;
  left: 0;
  right: 0;
  z-index: 2;
  /* justify-content: flex-end !important; */
}
</style>