import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import AOS from 'aos'
import 'aos/dist/aos.css'
import loading from 'vuejs-loading-screen'
import i18n from './i18n'

Vue.use(loading, {
  bg: '#dad7cd',
  // <hr style="border-top: .5rem dashed #3a5a40; width: 90vw;">
  slot: `
      <div class="banana c" style="display: flex; justify-content: center; margin: 6rem 0;">
        <img class="logo" width="450px" viewBox="0 0 42 27" src="https://www.gifmaker.net/src/3f588d1b-2579-48e1-af0e-c9b262725d23/default.gif">
        </img>
      </div>
  `
})
Vue.config.productionTip = false
AOS.init()

router.beforeEach((to, from, next) => {
  let language = to.params.lang
  if (!language) {
    language = 'en'
  }
  i18n.locale = language
  next()
})


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')