<template>
  <v-app id="app">
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  color: #4c3d3b !important;
  /* font-family: "Gothic A1", sans-serif; */
  font-family: "'Josefin Slab'", sans-serif;
  /* font-family: "Lato", sans-serif; */
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.v-application a {
  color: black !important;
}

.v-application .content p {
  margin-bottom: 0;
}
</style>