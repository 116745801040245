import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import ProductByCategory from '../views/ProductByCategory'
import AllProduct from '../views/AllProduct'
import i18n from '../i18n'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: `${i18n.locale }`
  },
  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: 'category/:category',
        name: 'productByCategory',
        component: ProductByCategory
      },
      {
        path: 'products',
        name: 'productList',
        component: AllProduct
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router