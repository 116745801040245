<template>
  <v-container class="product-list">
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        v-for="(product, i) in productData"
        :key="i"
        align="center"
        justify="center"
        :class="isMobile ? 'pa-2' : ''"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
      >
        <ProductCard
          :isMedScreen="isMedScreen"
          :isMobile="isMobile"
          :product="product"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
export default {
  name: "ProductList",
  components: {
    ProductCard,
  },
  data() {
    return {};
  },
  props: ["isMobile", "isMedScreen", "productData"],
  created () {
    // this.$isLoading(true)
  },
};
</script>

<style scoped>
hr {
  border: none;
  border-top: .5rem dashed #3a5a40;
  color: #3a5a40;
  background-color: #fff;
  height: 1px;
  width: 100%;
  letter-spacing: 1rem;
}
</style>