import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

var config = {
  apiKey: "AIzaSyCPOhEIq4OjIHCtSspm9wlv-rD8IoY3psE",
  authDomain: "royalnatural68.firebaseapp.com",
  projectId: "royalnatural68",
  storageBucket: "royalnatural68.appspot.com",
  messagingSenderId: "872716790016",
  appId: "1:872716790016:web:d31cc3d3d9bcfb6bf06be5"
}

// firebase.initializeApp(config);
const app = initializeApp(config)

const db = getFirestore(app)

export {
  db
}