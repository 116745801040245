<template>
  <div class="text-center">
    <v-menu top :close-on-click="closeOnClick">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class=""
          fab
          dark
          color="#DAD7CD"
          fixed
          bottom
          right
          flex
          v-bind="attrs"
          v-on="on"
        >
          <img width="35px" :src="flagIcon" alt="" srcset="" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="onSelectLang(item)"
        >
          <img class="pr-2" width="35px" :src="item.flag" alt="" srcset="" />
          <v-list-item-title>{{ item.langDisplay }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { enFlag, idFlag, krFlag } from '../utils/flagUrl';
export default {
  name: "TranslateButton",
  data() {
    return {
      flagIcon: "",
      items: [
        {
          langDisplay: "English",
          lang: "en",
          flag: enFlag
        },
        {
          langDisplay: "Korean",
          lang: "kr",
          flag: krFlag
        },
        {
          langDisplay: "Indonesian",
          lang: "id",
          flag: idFlag
        },
      ],
      closeOnClick: true,
    };
  },
  created() {
    // get lang
    const lang = this.$router.currentRoute.params.lang;
    this.$i18n.locale = lang;
    if (lang === "en") {
      this.flagIcon = enFlag
    } else if (lang === "kr") {
      this.flagIcon = krFlag
    } else {
      this.flagIcon = idFlag
    }
  },
  methods: {
    onSelectLang(langData) {
      const { lang, flag } = langData;
      this.flagIcon = flag;
      this.$i18n.locale = lang;
      this.$router.push({
        params: { lang: lang },
      });
      this.$router.go(0);
    },
  },
};
</script>

<style scoped>
.content-wrapper {
}
</style>