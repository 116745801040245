<template>
  <v-footer padless>
    <v-card
      flat
      tile
      class="white--text text-center"
      width="100%"
      color="#3a5a40"
    >
      <v-card-text class="white--text pt-10 bold">
        <h3>GET IN TOUCH</h3>
      </v-card-text>

      <v-card-text class="white--text pt-2 pb-0 bold">
        <h4>+62 85718072800</h4>
      </v-card-text>

      <v-card-text class="white--text pt-5 pb-0 bold">
        <h4>HELLO@ROYALNATURAL.NET</h4>
      </v-card-text>

      <v-card-text class="white--text pt-5 pb-10 bold">
        <h4>
          JL RUKO TAMAN HOLANDIA NO.26<br />
          PANUNGGANGAN BARAT, CIBODAS, <br />KOTA TANGERANG 15139
        </h4>
      </v-card-text>

      <!-- <v-card-text class="mb-5">
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
          @click="toWhatsapp()"
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text> -->

      <v-divider light></v-divider>

      <v-card-text class="white--text">
        Copyright © RoyalNatural 2022
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterMain",
  data: () => ({
    icons: ["mdi-whatsapp"],
  }),
  methods: {
    toWhatsapp() {
      const url = "https://api.whatsapp.com/send";
      let text = "Annyeonghaseyo Royal Nature!";
      window.open(
        url + "?phone=+6285881642222&text=" + encodeURIComponent(text)
      );
    },
  },
};
</script>

<style scoped>
.bg-green {
  background-color: #3a5a40;
}
.footer {
  background-color: #3a5a40;
}
.bold {
  font-weight: bold;
}
h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1rem;
}
.theme--light.v-divider {
  border-color: #dad7cd !important;
}
</style>