export const desktopContent = [{
    content: `<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1" alt="" srcset="" width="100%" height="90%">`,
  },
  {
    title: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">Kopi Luwak</b></div>',
    KRtitle: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">루왁커피</b></div>',
    content: `
      <b style="font-size: 1.2rem;color: #3a5a40">
        "Very good quality coffee with no broken beans. The aroma is very nice, the taste is balanced and complex! Friends and Family from Korea even kept asking to bring them more!"
      </b>`,
    KRcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"원두가 깨지지 않은 아주 좋은 품질의 커피. 향기가 매우 좋고 맛이 균형 잡히고 복잡합니다! 한국에서 온 친구와 가족은 계속해서 더 많이 가져 오라고 요청했습니다!"</b>',
    IDcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"Kualitas kopi yang bagus, tidak ada biji yang pecah. Aromanya wangi banget, rasa nya balance dan complex! Keluarga dan teman di Korea sampe titip semua setelah mencoba!"</b>',
  },
  {
    content: `<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-wash.png?alt=media&token=318eb7cc-6f72-497c-8850-f20b941a7f66" alt="" srcset="" width="90%" height="90%">`,
  },
  {
    title: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">Geranium <br>Face Wash</b></div>',
    KRtitle: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">페이스워시</b></div>',
    content: '<b style="font-size: 1.2rem;color: #3a5a40">"Good delivery ^^ Sent with great care Thank you for your kindness boss ^^ I got it at an event last time, loved it very much  and I want to buy it again. I\'\ve tried a variety of good cleansing foams, but really satisfied with this one, not only does it remove makeup well, but after washing i feel my skin with more moist, softer and brighter"</b>',
    KRcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"택배 잘받았습니다^^~ 세심하게 신경써서 보내주신 사장님 성의에 감사드립니다^^~ 페이스워시와 제라늄 에센스 오일은 저번 이벤트때  받아서 써보고 너무 좋아서 또  사고싶어서 언제 구매가능한지 몇번이나 연락드렸습니다~ 여러가지 좋다는 클렌징폼은 써 봤지만 정말 만족도 최고였습니다~ 화장이 잘 지워지는 것은 물론이고 씻고난뒤에 촉촉함과 뽀송함 등등~ "</b>',
    IDcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"Pengiriman baik dan dikirim dengan sangat hati-hati. Terima kasih atas kebaikannya bos^^ Saya mendapatkannya di sebuah acara, sangat menyukainya dan Saya ingin membelinya lagi. Saya sudah mencoba berbagai busa pembersih yang bagus, tapi sungguh puas dengan yang ini, tidak hanya menghapus makeup dengan baik, tetapi setelah mencuci saya merasa kulit saya lebih lembab, lebih lembut dan lebih cerah"</b>',
  },
  {
    content: `<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-spray.png?alt=media&token=929c1fb0-ba21-44a0-820b-cc64d0f511b3" alt="" srcset="" width="95%" height="95%">`,
  },
  {
    title: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">Geranium <br>Essential Oil</b></div>',
    KRtitle: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">제라늄에센스오일</b></div>',
    content: '<b style="font-size: 1.2rem;color: #3a5a40">"I have dry skin, so applying a moisturizer won\'\t help.If you apply any other oil, it becomes sticky and pimples.But this oil is really different.Recommend to those around you!I want to buy it as soon as it sells ^^"</b>',
    KRcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"제가 건성피부라서 수분크림을  발라도 해결되지 않고 다른 오일은 바르면 끈적이고 뾰루찌도 나고 그래서 조심스러웠는데 이 오일은 정말 다르더라구요^^~ 주변분들에게도 강추!!! 판매하시면 빨리 구매하고 싶습니다^^~"</b>',
    IDcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"Saya memiliki kulit kering, jadi mengoleskan pelembab tidak akan membantu. Jika Anda menerapkan minyak lain, itu menjadi lengket dan jerawat. Tapi minyak ini benar-benar berbeda. Rekomendasikan kepada orang-orang di sekitar kita! Saya ingin membelinya segera ketika dijual lagi ^^"</b>',
  },
  {
    content: `<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/shampoo.png?alt=media&token=986e3703-0d2d-4e09-97aa-825a72b28aba" alt="" srcset="" width="90%" height="90%">`,
  },
  {
    title: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">Geranium <br>Shampoo</b></div>',
    KRtitle: '<div class="mb-5"><b style="font-size: 2.5rem;color: #3a5a40; line-height: 1;">샴푸</b></div>',
    content: '<b style="font-size: 1.2rem;color: #3a5a40">"Because of menopause, I sweat a lot from my hair. So even if I wash it often, it has a musty smell, but this product doesn\'\t have that smell. Its very light and refreshing after washing my hair."</b>',
    KRcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"갱년기다보니 머리에서 땀이 무척 많이 남니다 그래서 자주 감아도 꿉꿉한 냄새가 났었는데이 제품은 그런 냄새가 없어 좋네요 머리감은후 가볍고 상쾌합니다"</b>',
    IDcontent: '<b style="font-size: 1.2rem;color: #3a5a40">"Karena menopause, saya banyak berkeringat dari rambut saya. Jadi walaupun sering dicuci, ada bau apek, tapi produk ini nggak ada baunya. Ini sangat ringan dan menyegarkan setelah mencuci rambut saya."</b>',
  },
]

export const mobileContent = [{
    title: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1rem;color: #3a5a40; line-height: 1;">Kopi Luwak</b></div>',
    KRtitle: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1.5rem;color: #3a5a40; line-height: 1;">루왁커피</b></div>',
    content: '<p style="font-size: .9rem;color: #3a5a40"; line-height: 1;>"Very good quality coffee with no broken beans. The aroma is very nice, the taste is balanced and complex! Friends and Family from Korea even kept asking to bring them more!"</p>',
    KRcontent: '<b style="font-size: .9rem;color: #3a5a40">"원두가 깨지지 않은 아주 좋은 품질의 커피. 향기가 매우 좋고 맛이 균형 잡히고 복잡합니다! 한국에서 온 친구와 가족은 계속해서 더 많이 가져 오라고 요청했습니다!"</b>',
    IDcontent: '<p style="font-size: .9rem;color: #3a5a40">"Kualitas kopi yang bagus, tidak ada biji yang pecah. Aromanya wangi banget, rasa nya balance dan complex! Keluarga dan teman di Korea sampe titip semua setelah mencoba!"</p>',
  },
  {
    title: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-wash.png?alt=media&token=318eb7cc-6f72-497c-8850-f20b941a7f66" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1rem;color: #3a5a40; line-height: 1;">Geranium <br>Face Wash</b></div>',
    KRtitle: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-wash.png?alt=media&token=318eb7cc-6f72-497c-8850-f20b941a7f66" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1.5rem;color: #3a5a40; line-height: 1;">페이스워시</b></div>',
    content: '<p class="mb-5" style="font-size: .9rem;color: #3a5a40"; line-height: 1;>"Good delivery ^^ Sent with great care Thank you for your kindness boss ^^ I got it at an event last time, loved it very much  and I want to buy it again. I\'\ve tried a variety of good cleansing foams, but really satisfied with this one, not only does it remove makeup well, but after washing i feel my skin with more moist, softer and brighter"</p>',
    KRcontent: '<b style="font-size: .9rem;color: #3a5a40">"택배 잘받았습니다^^~ 세심하게 신경써서 보내주신 사장님 성의에 감사드립니다^^~ 페이스워시와 제라늄 에센스 오일은 저번 이벤트때  받아서 써보고 너무 좋아서 또  사고싶어서 언제 구매가능한지 몇번이나 연락드렸습니다~ 여러가지 좋다는 클렌징폼은 써 봤지만 정말 만족도 최고였습니다~ 화장이 잘 지워지는 것은 물론이고 씻고난뒤에 촉촉함과 뽀송함 등등~ "</b>',
    IDcontent: '<p style="font-size: .9rem;color: #3a5a40">"Pengiriman baik dan dikirim dengan sangat hati-hati. Terima kasih atas kebaikannya bos^^ Saya mendapatkannya di sebuah acara, sangat menyukainya dan Saya ingin membelinya lagi. Saya sudah mencoba berbagai busa pembersih yang bagus, tapi sungguh puas dengan yang ini, tidak hanya menghapus makeup dengan baik, tetapi setelah mencuci saya merasa kulit saya lebih lembab, lebih lembut dan lebih cerah"</p>',
  },
  {
    title: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-spray.png?alt=media&token=929c1fb0-ba21-44a0-820b-cc64d0f511b3" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1rem;color: #3a5a40; line-height: 1;">Geranium <br>Face Spray</b></div>',
    KRtitle: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-spray.png?alt=media&token=929c1fb0-ba21-44a0-820b-cc64d0f511b3" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1.5rem;color: #3a5a40; line-height: 1;">제라늄에센스오일</b></div>',
    content: '<p style="font-size: .9rem;color: #3a5a40"; line-height: 1;>"I have dry skin, so applying a moisturizer won\'\t help.If you apply any other oil, it becomes sticky and pimples.But this oil is really different.Recommend to those around you!I want to buy it as soon as it sells ^^"</p>',
    KRcontent: '<b style="font-size: .9rem;color: #3a5a40">"제가 건성피부라서 수분크림을  발라도 해결되지 않고 다른 오일은 바르면 끈적이고 뾰루찌도 나고 그래서 조심스러웠는데 이 오일은 정말 다르더라구요^^~ 주변분들에게도 강추!!! 판매하시면 빨리 구매하고 싶습니다^^~"</b>',
    IDcontent: '<p style="font-size: .9rem;color: #3a5a40">"Saya memiliki kulit kering, jadi mengoleskan pelembab tidak akan membantu. Jika Anda menerapkan minyak lain, itu menjadi lengket dan jerawat. Tapi minyak ini benar-benar berbeda. Rekomendasikan kepada orang-orang di sekitar kita! Saya ingin membelinya segera ketika dijual lagi ^^"</p>',
  },
  {
    title: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/shampoo.png?alt=media&token=986e3703-0d2d-4e09-97aa-825a72b28aba" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1rem;color: #3a5a40; line-height: 1;">Geranium <br>Shampoo</b></div>',
    KRtitle: '<img src="https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/shampoo.png?alt=media&token=986e3703-0d2d-4e09-97aa-825a72b28aba" alt="" srcset="" width="65%"><div class="mb-2"><b style="font-size: 1.5rem;color: #3a5a40; line-height: 1;">샴푸</b></div>',
    content: '<p style="font-size: .9rem;color: #3a5a40"; line-height: 1;>"Because of menopause, I sweat a lot from my hair. So even if I wash it often, it has a musty smell, but this product doesn\'\t have that smell. Its very light and refreshing after washing my hair."</p>',
    KRcontent: '<b style="font-size: .9rem;color: #3a5a40">"갱년기다보니 머리에서 땀이 무척 많이 남니다 그래서 자주 감아도 꿉꿉한 냄새가 났었는데이 제품은 그런 냄새가 없어 좋네요 머리감은후 가볍고 상쾌합니다"</b>',
    IDcontent: '<p style="font-size: .9rem;color: #3a5a40">"Karena menopause, saya banyak berkeringat dari rambut saya. Jadi walaupun sering dicuci, ada bau apek, tapi produk ini nggak ada baunya. Ini sangat ringan dan menyegarkan setelah mencuci rambut saya."</p>',
  },
]