<template>
  <div>
    <div class="item-recommend-wrapper">
      <div
        class="item-recommend"
        :class="{ 'item-recommend-mobile': isMobile }"
        id="ItemRecommend"
      >
        <img
          v-for="(item, index) in recommendList"
          :key="index"
          class="item"
          :src="item.img"
          alt="recommend items"
          data-animation="stop"
          @mouseover="onHover(item.ENname, item.KRname, item.price)"
          @mouseleave="onLeave"
          @click="onClick(item.ENname, item.KRname)"
        />
        <span
          class="title"
          :class="{ 'title-mobile': isMobile }"
          v-html="titleContent"
        >
        </span>
      </div>
      <div
        class="item-info"
        :class="{ 'item-info-mobile': isMobile }"
        v-if="showItemInfo"
        data-aos="fade-zoom"
        data-aos-easing="ease-in-back"
        data-aos-duration="300"
      >
        <div class="item-name" :class="{ 'item-name-mobile': isMobile }">
          {{ itemInfo.name }}
        </div>
        <div class="item-price" :class="{ 'item-price-mobile': isMobile }">
          {{ itemInfo.price }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemRecommend",
  data() {
    return {
      titleContent: `RECOMMENDATION &nbsp;&nbsp;&nbsp; RECOMMENDATION &nbsp;&nbsp;&nbsp;RECOMMENDATION &nbsp;&nbsp;&nbsp;`,
      showItemInfo: false,
      clicked: 0,
      itemInfo: {
        name: "",
        price: "",
      },
      recommendList: [
        {
          ENname: "Kopi Luwak",
          KRname: "루왁커피",
          price: "Rp250.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1",
        },
        {
          ENname: "Honey",
          KRname: "목청",
          price: "Rp350.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/madu.png?alt=media&token=1b2c1b15-138e-4c35-8cd4-ba3475a52327",
        },
        {
          ENname: "Noni Juice",
          KRname: "노니원액",
          price: "Rp220.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/noni-juice.png?alt=media&token=0a556079-9bca-4155-bc7d-9565bdd4dd5b",
        },
        {
          ENname: "Kopi Luwak",
          KRname: "루왁커피",
          price: "Rp250.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1",
        },
        {
          ENname: "Geranium Face Spray",
          KRname: "제라늄에센스오일",
          price: "Rp120.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-spray.png?alt=media&token=929c1fb0-ba21-44a0-820b-cc64d0f511b3",
        },
        {
          ENname: "Virgin Coconut Oil",
          KRname: "코코넛오일",
          price: "Rp75.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1",
        },
        {
          ENname: "Honey",
          KRname: "목청",
          price: "Rp350.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/madu.png?alt=media&token=1b2c1b15-138e-4c35-8cd4-ba3475a52327",
        },
        {
          ENname: "Noni Juice",
          KRname: "노니원액",
          price: "Rp220.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/noni-juice.png?alt=media&token=0a556079-9bca-4155-bc7d-9565bdd4dd5b",
        },
        {
          ENname: "Kopi Luwak",
          KRname: "루왁커피",
          price: "Rp250.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1",
        },
        {
          ENname: "Geranium Face Spray",
          KRname: "제라늄에센스오일",
          price: "Rp120.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/face-spray.png?alt=media&token=929c1fb0-ba21-44a0-820b-cc64d0f511b3",
        },
        {
          ENname: "Virgin Coconut Oil",
          KRname: "코코넛오일",
          price: "Rp75.000",
          img: "https://firebasestorage.googleapis.com/v0/b/royalnatural68.appspot.com/o/kopi-luwak.png?alt=media&token=fe943067-0fb3-4fc5-ba04-fa2c3345cec1",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
    isEN() {
      const lang = this.$router.currentRoute.params.lang;
      return lang === "en" || lang === "id";
    },
  },
  mounted() {
    const jstoggle = document.getElementById("ItemRecommend");

    jstoggle.addEventListener("mouseover", () => {
      const animations = document.querySelectorAll("[data-animation");
      animations.forEach((animation) => {
        animation.style.animationPlayState = "paused";
      });
    });

    jstoggle.addEventListener("mouseleave", () => {
      const animations = document.querySelectorAll("[data-animation");
      animations.forEach((animation) => {
        animation.style.animationPlayState = "running";
      });
    });
  },
  methods: {
    onHover(ENname, KRname, price) {
      // this.titleContent = `KOPI LUWAK &nbsp;&nbsp;&nbsp; KOPI LUWAK &nbsp;&nbsp;&nbsp; KOPI LUWAK &nbsp;&nbsp;&nbsp;`;
      this.clicked += 1;
      this.itemInfo.name = this.isEN ? ENname : KRname;
      this.itemInfo.price = price;
      this.showItemInfo = true;
    },
    onLeave() {
      this.clicked = 0;
      this.titleContent = `RECOMMEND ITEMS &nbsp;&nbsp;&nbsp; RECOMMEND ITEMS &nbsp;&nbsp;&nbsp;RECOMMEND ITEMS &nbsp;&nbsp;&nbsp;`;
      this.showItemInfo = false;
    },
    onClick(ENname, KRname) {
      this.clicked += 1
      if (!this.isMobile || (this.isMobile && this.clicked === 3)) {
        const url = "https://api.whatsapp.com/send";
        const lang = this.$router.currentRoute.params.lang;

        let text = `Annyeonghaseyo Royal Nature! I want to buy ${ENname} (${KRname})..`;
        if (lang === "id") {
          text = `Annyeonghaseyo Royal Nature! Mau beli ${ENname} (${KRname}) dong..`;
        } else if (lang === "kr") {
          text = `안녕하세요 Royal Nature! 내가 사고 싶은 ${KRname} (${ENname})..`;
        }
        window.open(
          url + "?phone=+6285881642222&text=" + encodeURIComponent(text)
        );
      }
    },
  },
};
</script>

<style scoped>
.item-recommend-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
}

.item-recommend {
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.item-recommend div {
  height: 400px;
  min-width: 300px;
  margin-left: 100px;
  animation: slide 15s linear infinite;
}

.item-recommend .item {
  max-height: 430px;
  max-width: 650px;
  margin-left: 180px;
  animation: slide 13s linear infinite;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: cover;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.item-recommend-mobile .item {
  max-height: 330px;
  max-width: 200px;
  margin-left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.v-image__image--cover {
  background-size: contain !important;
}
.v-responsive {
  flex: 0;
}

.item-info {
  position: absolute;
  font-size: 3rem;
  bottom: 150px;
  color: #3a5a40;
  width: 100%;
  text-align: center;
  height: 8rem;
}

.item-price {
  color: #a7a59c !important;
  font-size: 2rem;
  position: absolute;
  width: 100%;
  margin-top: 3.5rem;
}

.item-name {
  font-family: "Josefin Slab", serif;
  position: absolute;
  width: 100%;
  font-weight: 600;
}

.item-name-mobile {
  font-size: 2rem;
}

.item-price-mobile {
  color: #a7a59c !important;
  font-size: 2rem;
  position: absolute;
  width: 100%;
  margin-top: 2.4rem;
  font-size: 1.5rem;
}

.item {
  cursor: pointer;
  transition: transform 0.5s, filter 0.5s ease-in-out;
  /* Equal to rotateZ(45deg) */
}

.item:hover {
  filter: grayscale(100%);
  transform: scale(1.01);
}

.item-recommend span {
  -webkit-animation: text-slide 180s linear infinite;
  animation: text-slide 180s linear infinite;
  background-repeat: no-repeat;
  height: 10rem !important;
  width: 100%;
  position: absolute;
  padding-top: 4rem;
  cursor: pointer;
}

.title {
  position: absolute;
  font-family: "Josefin Slab", serif !important;
  white-space: nowrap;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #3a5a40;
  font-size: 8rem !important;
  text-transform: uppercase;
  transition: opacity 0.5s ease;
}

.title-mobile {
  font-size: 60px !important;
  margin: 0 !important;
  line-height: 5rem;
}

.item-info-mobile {
  padding-top: 5rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1500px, 0, 0); /* The image width */
  }
}

@keyframes text-slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1000px, 0, 0);
    /* The image width */
  }
}
</style>