<template>
  <div
    class="card-wrapper"
    @click="onSelectProduct"
    @mouseover="onHover"
    @mouseleave="onLeave"
  >
    <v-card
      flat
      tile
      class="card"
      :class="{ 'mt-15': !isFromRecommend }"
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-delay="100"
    >
      <div
        class="image-wrapper img-hover-zoom--colorize"
        :style="
          isMobile
            ? 'height: 230px'
            : isMedScreen
            ? 'height: 350px'
            : 'height: 350px'
        "
      >
        <img
          class="product-image"
          width="100%"
          height="100%"
          :src="product.image"
        />
      </div>
      <div v-if="!isFromRecommend">
        <v-card-title>
          <div v-if="isEN" class="card-title">{{ product.ENname }}</div>
          <div v-else class="card-title">
            <b> {{ product.KRname }}</b>
          </div>
        </v-card-title>
        <v-card-text class="pb-2 card-price">
          <transition name="slide-fade" mode="out-in">
            <button class="ma-0" v-if="!isHover" key="1">
              {{ moneyFormatter(product.price || 0) }}
            </button>
            <button class="ma-0" v-else-if="isHover" key="2">
              {{ product.weight }}
            </button>
            <!-- </transition>
        <transition name="fade" > -->
          </transition>
          <!-- <a class="price-weight" href="#" data-replace="1 gr"><span>Rp150.000</span></a> -->
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import moneyFormatter from "@/utils/moneyFormatter";

export default {
  name: "ProductCard",
  data() {
    return {
      moneyFormatter,
      isHover: false,
    };
  },
  props: ["isMobile", "isMedScreen", "product", "isFromRecommend"],
  computed: {
    isEN() {
      const lang = this.$router.currentRoute.params.lang;
      return lang === "en" || lang === "id";
    },
  },
  methods: {
    onSelectProduct() {
      const ENname = this.product.ENname.charAt(0).toUpperCase() + this.product.ENname.slice(1);
      const KRname = this.product.KRname
      const url = "https://api.whatsapp.com/send";
      const lang = this.$router.currentRoute.params.lang;

      let text = `Annyeonghaseyo Royal Nature! I want to buy ${ENname} (${KRname})..`;
      if (lang === 'id') {
        text = `Annyeonghaseyo Royal Nature! Mau beli ${ENname} (${KRname}) dong..`;
      } else if (lang === 'kr') {
        text = `안녕하세요 Royal Nature! 내가 사고 싶은 ${KRname} (${ENname})..`;
      }
      window.open(
        url + "?phone=+6285881642222&text=" + encodeURIComponent(text)
      );
    },
    onHover() {
      this.isHover = true;
    },
    onLeave() {
      this.isHover = false;
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 20px;
  font-weight: 600;
  word-break: break-word;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #3a5a40;
  font-family: "'Josefin Slab'", sans-serif;
  padding-top: 0.5rem;
  text-transform: capitalize; 
  /* text-shadow: -0.7px -0.7px 0 #3a5a40, 0.7px -0.7px 0 #3a5a40,
    -0.7px 0.7px 0 #3a5a40, 0.7px 0.7px 0 #3a5a40;
  color: #dad7cd; */
  /* text-transform: uppercase; */
}

.card-price {
  font-family: "'Josefin Slab'", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #a7a59c !important;
}

.v-card__title {
  padding: 0;
}

.image-wrapper {
  overflow: hidden;
}

.img-hover-zoom--colorize img {
  transition: transform 0.5s, filter 0.5s ease-in-out;
  filter: grayscale(0);
}

/* The Transformation */
.img-hover-zoom--colorize:hover img {
  filter: grayscale(100%);
  transform: scale(1.01);
}

.card-wrapper {
  cursor: pointer;
}

/* card color here */
.card {
  background-color: #dad7cd;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.product-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>